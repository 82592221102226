var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    {
      style: { "margin-bottom": !_vm.showNav ? 0 : null },
      attrs: {
        id: "page-admin-footer",
        role: "contentinfo",
        "aria-label": "Site Page Footer",
      },
    },
    [
      _c("interior-page-footer"),
      _vm._v(" "),
      _c("client-footer"),
      _vm._v(" "),
      _vm.showNav ? _c("StickyFooter") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }