var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `utility-block-${_vm.state}`,
      style: `height:${_vm.height}px`,
      attrs: { id: "utility-block" },
    },
    [
      !_vm.blank
        ? [
            _vm.title
              ? _c("h2", {
                  staticClass: "utility-block-title",
                  domProps: { textContent: _vm._s(_vm.title) },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.text
              ? _c("p", {
                  staticClass: "utility-block-text mb-spacing-md",
                  domProps: { textContent: _vm._s(_vm.text) },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.state === "loading" && !_vm.blank && !_vm.title
            ? _c("b-spinner", { attrs: { small: "" } })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }