var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loading-block", style: `height:${_vm.height}px` },
    [
      !_vm.hideSpinner
        ? _c(
            "span",
            { staticClass: "spinner-border", attrs: { role: "status" } },
            [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideText
        ? _c("span", { domProps: { textContent: _vm._s(_vm.text) } })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }